import React, { useCallback, memo } from 'react';
import { StyleSheet, View } from 'react-native';
import KeypadNumber from './KeypadNumber';
import { COLOR_MAP } from '../../screens/Main/game/constants';
import { useDispatch, useSelector } from 'react-redux';
import { keyPadNumbersActions } from '../../redux/keyPadNumbersSlice';
import colors from '../../strings/colors';
import { useBeep } from '../../screens/Main/game/sounds';
import Toast from 'react-native-toast-message';

// Extracted the styles outside of the KeyPad component 
// to avoid recreating the object in each render.
const styles = StyleSheet.create({
    key_pad: {
        flex:2,
        justifyContent: 'center',
        flexDirection:'column',
        alignItems:'center',
        width:'90%',
        height: '100%',
        backgroundColor:colors.light_blue,
        borderRadius: 10,
        marginTop: 1,
        padding:10,
    },
    keyPadRow: {
        flex:1,
        flexDirection:'row',
        justifyContent:'center',
        width:'100%',
        marginBottom: 15,
    },
    keyPadNumber: {
        flex:1,
        justifyContent:'center',
        alignItems:'center',
    }
});

const KeyPad = () => {
    const keypad_numbers = useSelector(state => state.keyPadNumbers.keyPadNumbers);
    const color_state = useSelector(state => state.color); 
    const {isSpinning} = useSelector((state) => state.game);
    const {isSoundOn} = useSelector((state) => state.sound);

    const dispatch = useDispatch();

    const playBeep = useBeep();

    // Using useCallback to memoize the onKeyPress function 
    // to avoid its recreation on every render.
    const onKeyPress = useCallback((keyPadNumber, keyClicked) => {
        if(isSpinning){
            Toast.show({
                type: 'error',
                position: 'top',
                text1: 'Spinning in progress',
                text2: 'You cannot place a bet while spinning'   
            });

            return;
        } 
        if(isSoundOn){
            playBeep();
        }
       
        dispatch(keyPadNumbersActions.toggleKey({ number: keyPadNumber, key: keyClicked }));
    }, [dispatch,isSpinning,isSoundOn,playBeep]);  // Added dispatch as a dependency because it is used inside the function.

    // Using a map function to avoid repetitive JSX and logic
    const renderRows = [
        [null, keypad_numbers[0], null],
        [keypad_numbers[1], keypad_numbers[2], keypad_numbers[3]],
        [keypad_numbers[4], keypad_numbers[5], keypad_numbers[6]],
        [keypad_numbers[7], keypad_numbers[8], keypad_numbers[9]],
        [keypad_numbers[10], keypad_numbers[11], keypad_numbers[12]],
    ];

    return (
        <View style={[styles.key_pad, {backgroundColor:COLOR_MAP[color_state.value]['dark']}]}>
            {renderRows.map((row, rowIndex) => (
                <View key={rowIndex} style={styles.keyPadRow}>
                    {row.map((keypadNumber, colIndex) => (
                        <View key={colIndex} style={styles.keyPadNumber}>
                            {keypadNumber && 
                                <KeypadNumber 
                                    number={keypadNumber} 
                                    backgroundColor={keypadNumber?.color} 
                                    onPress={onKeyPress}
                                />
                            }
                        </View>
                    ))}
                </View>
            ))}
        </View>
    );
};

// Using React.memo to avoid unnecessary re-renders of the KeyPad component
export default memo(KeyPad);
