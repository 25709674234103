/**
 * multiplier-constants.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

const WIN_10 = [3, 1, 0, 2, 4, 3, 2, 0, 3, 1, 4, 3, 1, 2, 0];
const WIN_25 = [3, 1, 0, 2, 4, 3, 4, 0, 3, 1, 4, 3, 4, 0, 3];
const WIN_50 = [3, 1, 0, 2, 4, 3, 4, 2, 0, 3, 1, 4, 3, 0, 1];
const WIN_100 = [3, 1, 0, 2, 4, 3, 2, 0, 3, 1, 4, 3, 1, 4];
const WIN_1000 = [3, 1, 0, 2, 4, 3, 2, 0, 3, 1, 4, 3, 1, 2];

const MULTIPLIER_MAP = {
  10: WIN_10,
  25: WIN_25,
  50: WIN_50,
  100: WIN_100,
  1000: WIN_1000,
};

export { WIN_10, WIN_25, WIN_50, WIN_100, WIN_1000, MULTIPLIER_MAP };
