import React, {useState,useEffect} from 'react';
import { ActivityIndicator, View, Text, TextInput, Button, Image, StyleSheet } from 'react-native';
import RoundedInput from '../../components/common/RoundedInput';
import RoundedButton from '../../components/common/RoundedButton';
import colors from '../../strings/colors';
import { 
  useLoginMutation,
  useForgotPasswordMutation
 } from '../../redux/api';
import { useNavigation } from '@react-navigation/core';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../redux/userSlice';
import Loader from '../../components/common/Loader';
import SiskusIcon from '../../components/common/SiskusIcon';
import Toast from 'react-native-toast-message';


const Login = () => {

  const [username,setUsername] = useState('');

  const [password,setPassword] = useState('');

  const [status,setStatus] = useState('');

  const [statusColor,setStatusColor] = useState(colors.orange);

  const [login, loginResult ] = useLoginMutation();

  const [forgotPassword, forgotPasswordResult ] = useForgotPasswordMutation();

  const navigation = useNavigation();

  const dispatch = useDispatch();
  const user_state = useSelector(state => state.user);

  //handle password change

  const handlePasswordChange = (text) => {
    setPassword(text);
  }

  //handle login

  const handleLogin = () => {
    setStatus('Logging in...');

    login({cellphone:user_state.cellphone,password:password});
  }

  //handle forgot password

  const handleForgotPassword = () => {
    setStatus('Requesting password reset...');
    forgotPassword({cellphone:user_state.cellphone});
  }

  //handleStartOver

  const handleStartOver = () => {
    dispatch(userActions.removeUser());
    navigation.navigate('Search');
  }

  //check if user is logged in already

  useEffect(() => {
     if(user_state){
         if(user_state._id === ''){
          navigation.navigate('Search');
        }
      }
       
  }, []);

  useEffect(() => {
    if(loginResult.isSuccess){
      setStatus(loginResult.data.message);
      if (loginResult.data.success === true){
          //saving user to redux
          dispatch(userActions.setUser(loginResult.data));
          dispatch(userActions.setIsLoggedIn(true));
          //navigate to jackpot
          navigation.navigate('Jackpot');
      }else{
        Toast.show({
          type:'error',
          text1:'Error',
          text2:loginResult.data.message
        });
      }
    }
    if(loginResult.isError){
      Toast.show({
        type:'error',
        text1:'Error',
        text2:'Login failed'
      });
      console.log(loginResult.error);
    }
    if(forgotPasswordResult.isSuccess){
      setStatusColor(colors.green);
      setStatus(forgotPasswordResult.data.message);
    }
    if(forgotPasswordResult.isError){
      Toast.show({
        type:'error',
        text1:'Error',
        text2:'Password reset failed'
      });
    }
  }, [loginResult.isLoading, forgotPasswordResult.isLoading]);


  if(loginResult.isLoading || forgotPasswordResult.isLoading){
    return (
      <Loader/>
    );
  }


  return (
   <View style={styles.container}>
    
        
        <SiskusIcon title={'ENTER YOUR PASSWORD'} description={'WELCOME BACK TO SISKUS'} />

        <RoundedInput placeholder="Password" onChangeText={handlePasswordChange} isPassword={true}/>

        <RoundedButton title="LOGIN" onPress={handleLogin} />
        <RoundedButton title="START OVER" onPress={handleStartOver} />

        <Text style={styles.forgotPasswordText} onPress={handleForgotPassword}>REQUEST A NEW PASSWORD</Text>

        <Text style={[styles.statusText,{color:statusColor}]}>{status}</Text>
    </View>
  );
};

const styles = StyleSheet.create({

  container: {
      flex: 1,
      backgroundColor: colors.white,
      justifyContent: 'center',
      alignItems: 'center',
      padding: 20,
  },
 
 

  statusText: {
    color: colors.orange,
    marginTop: 20,
    marginBottom: 20,
    textAlign: 'center',
  },
  forgotPasswordText: {
    fontFamily: 'Poppins-Bold',
    color: "blue",
    marginTop: 20,
    textAlign: 'center',
    fontSize: 14,
  },
});

export default Login;
