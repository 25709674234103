/**
 * MyWebView.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */
import React from 'react';
import { WebView } from 'react-native-webview';

const MyWebView = ({ route }) => {
  const { url } = route.params;

  return (
    <WebView
      source={{ uri: url }}
      startInLoadingState={true}
    />
  );
};

export default MyWebView;
