
import React from 'react'
import { Text, StyleSheet, View } from 'react-native'
import colors from '../../strings/colors';

function MultiplierNumber({ number, isOn, width }) {

  return (
    <View style={[styles.container,{width:width}]}>
        <Text style={[styles.text,isOn && styles.on]}>
          {number}
        </Text>
    </View>
    
  )
}

const styles = StyleSheet.create({
   container: {
        fontFamily: 'Gloucester',
        backgroundColor:colors.red,
        borderRadius:10,
        justifyContent:'space-evenly',
        alignItems:'center',
        margin: 2,
        
   },

  on: {
    fontFamily: 'Gloucester',
    fontSize: 19,
    fontWeight: 'bold',
    color: '#fff',
    textShadowColor: '#fff',
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 8,
    
  },
  text:{
    fontFamily: 'Gloucester',
    color: colors.light_gray,
    fontSize: 18,
    margin: 'auto',
    fontWeight: 'bold',
  }
})


export default React.memo(MultiplierNumber)