import React from 'react'
import {StyleSheet,View,Text} from 'react-native'
import colors from '../../strings/colors'

function LEDDisplay({name,amount}) {
    
    return (
        <View style={styles.container}>
            <View style={styles.display}>
                <Text style={styles.displayAmount}>
                    {amount}
                </Text>
            </View>
            <Text style={styles.displayName}>{name}</Text>
        </View>
    )
}

const styles = StyleSheet.create({
    container:{
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column',
        height:70,
        width:80,
        backgroundColor:colors.deep_blue,
        borderRadius:10,
    },

    display:{
        flex:1,
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'row',
        height:30,
        width:80,
        backgroundColor:colors.black,
        marginBottom:7,
        borderRadius:10,
    },

    displayName:{
        flex:1,
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'row',
        fontFamily:'Gloucester',
        fontSize:16,
        color:'#eeee',
    },
    
    displayAmount:{
        
        justifyContent:'center',
        alignItems:'center',
        fontFamily:'Led',
        margin:'auto',
        fontSize:28,
        color:colors.red,
        textShadowColor: colors.red,
        textShadowOffset: { width: 1, height: 0 },
        textShadowRadius:20,
    }
})

export default React.memo(LEDDisplay)