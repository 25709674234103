/**
 * KeyPadNumbersSlice.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

import { createSlice } from '@reduxjs/toolkit';
import { KEYS_10K } from '../screens/Main/game/constants';
import colors from '../strings/colors';

export const keyPadNumbersSlice = createSlice({
    name: 'keyPadNumbers',
    initialState: {
        keyPadNumbers: [
            {number:0, color: colors.green,keys:[...KEYS_10K.keys]},
            {number:1, color: colors.red, keys:[...KEYS_10K.keys]},
            {number:2,color: colors.black, keys:[...KEYS_10K.keys]},
            {number:3, color: colors.red, keys:[...KEYS_10K.keys]},
            {number:4,color: colors.black, keys:[...KEYS_10K.keys]},
            {number:5, color: colors.red, keys:[...KEYS_10K.keys]},
            {number:6,color: colors.black, keys:[...KEYS_10K.keys]},
            {number:7, color: colors.red, keys:[...KEYS_10K.keys]},
            {number:8,color: colors.black, keys:[...KEYS_10K.keys]},
            {number:9, color: colors.red, keys:[...KEYS_10K.keys]},
            {number:10,color: colors.black, keys:[...KEYS_10K.keys]},
            {number:11, color: colors.red, keys:[...KEYS_10K.keys]},
            {number:12, color: colors.black, keys:[...KEYS_10K.keys]}
        ],
    },

    reducers: {
        //reset all keys to off
        resetAllKeys: (state) => {
            state.keyPadNumbers.forEach((keyPadNumber) => {
                keyPadNumber.keys.forEach(key => key.isOn = false);
            });
        },

        //toggle key state
        toggleKey: (state, action) => {
            const { number, key } = action.payload;
            const targetKey = state.keyPadNumbers[number].keys.find(k => k.number === key);
            if (targetKey) {
                targetKey.isOn = !targetKey.isOn;
            }
        },
    },
});

export const keyPadNumbersActions = keyPadNumbersSlice.actions;
