/**
 * gameSlice.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

import { createSlice } from "@reduxjs/toolkit";


export const gameSlice = createSlice({
    name: "game",
    initialState: {
        isSpinning: false,
        credit:0,
        winAmount:0,
    },
    reducers: {
        toggleIsSpinning: (state) => {
            state.isSpinning = !state.isSpinning;
        },
        setCredit: (state, action) => {
            state.credit = action.payload;
        },
        setWinAmount: (state, action) => {
            state.winAmount = action.payload;
        },

        //set isSpinning to true
        setIsSpinning: (state) => {
            state.isSpinning = true;
        },

        //set isSpinning to false
        setIsNotSpinning: (state) => {
            state.isSpinning = false;
        },

        //reset all 
        resetAll: (state) => {
            state.isSpinning = false;
            state.credit = 0;
            state.winAmount = 0;
        },
    },
});

export const gameActions = gameSlice.actions;