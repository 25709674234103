/**
 * Navigator.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */
import React, { useState, useCallback, useMemo, memo } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Image, View } from 'react-native';
import colors from '../strings/colors';
import Login from '../screens/Auth/Login';
import Register from '../screens/Auth/Register';
import Jackpot from '../screens/Main/Jackpot';
import PhoneSearch from '../screens/Auth/PhoneSearch';
import SendCredit from '../screens/Payments/SendCredit';
import Payin from '../screens/Payments/Payin';
import Payout from '../screens/Payments/Payout';
import Profile from '../screens/Main/Profile';
import ChangePassword from '../screens/Auth/ChangePassword';
import Settings from '../screens/Main/Settings';
import MyWebView from '../screens/Main/MyWebView';
import MenuIcon from '../components/jackpot/MenuIcon';
import SoundToggle from '../components/jackpot/SoundToggle';
import InternetConnectionStatusIcon from '../components/jackpot/InternetConnectionStatusIcon';
import MenuPopup from '../components/jackpot/MenuPopup';
import WhatsApp from '../components/common/WhatsApp';
import Wallet from '../screens/Payments/Wallet';

const logo = require('../../assets/images/siskus.svg');
const xmasLogo = require('../../assets/images/x-mas-siskus.png');

const Stack = createStackNavigator();

const currentMonth = new Date().getMonth() + 1;

const logoIcon = currentMonth > 10 ? xmasLogo : logo;

const LogoHeader = memo(() => (
    
    <Image
        style={{
            width: currentMonth>10? 200 : 130,
            height: currentMonth>10? 60 : 40,
            marginBottom: 10,
        }}
        source={logoIcon}
        resizeMode="contain"
    />
));

const RightHeader = memo(() => (
    <View style={{ flexDirection: 'row' }}>
        <WhatsApp key={Math.random()} number="+264818210855" message="👋Hey there! I found your number on the Siskus app!" />
        <SoundToggle key={Math.random()} />
    </View>
));

const LeftHeader = memo(() => {
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [icon, setIcon] = useState('menu-outline');

    const toggleMenu = useCallback(() => {
        if (isMenuVisible) {
            setIsMenuVisible(false);
            setIcon('menu-outline');
        } else {
            setIsMenuVisible(true);
            setIcon('close');
        }
    }, [isMenuVisible]);

    return (
        <View style={{ flexDirection: 'row' }}>
            <MenuIcon key={Math.random()} onPress={toggleMenu} icon={icon} />
            <InternetConnectionStatusIcon key={Math.random()} />
            <MenuPopup key={Math.random()} visible={isMenuVisible} close={toggleMenu} />
        </View>
    );
});

function Navigator() {
    const commonHeaderOptions = useMemo(() => ({
        headerTitle: () => <LogoHeader />,
        headerRight: () => <RightHeader />,
        headerLeft: () => <LeftHeader />,
        headerTitleAlign: 'center',
        headerStyle: {
            backgroundColor: colors.gray,
            shadowColor: '#fff',
        },
    }), []);

    return (
        <Stack.Navigator initialRouteName="Search">
            <Stack.Screen name="Login" options={{ headerShown: false }} component={Login} />
            <Stack.Screen name="Register" component={Register} options={{ headerTitle: "" }} />
            <Stack.Screen name="Search" options={{ headerShown: false }} component={PhoneSearch} />

            <Stack.Screen
                name="Jackpot"
                component={Jackpot}
                options={commonHeaderOptions}
            />
            <Stack.Screen name="SendCredit" component={SendCredit} options={{ headerTitle: "" }} />
            <Stack.Screen name="Wallet" component={Wallet} options={{ headerTitle: "" }} />
            <Stack.Screen name="Payin" component={Payin} options={{ headerTitle: "" }} />
            <Stack.Screen name="Payout" component={Payout} options={{ headerTitle: "" }} />
            <Stack.Screen name="Profile" component={Profile} options={{ headerTitle: "" }} />
            <Stack.Screen name="Settings" component={Settings} options={{ headerTitle: "" }} />
            <Stack.Screen name="ChangePassword" component={ChangePassword} options={{ headerTitle: "" }} />
            <Stack.Screen name="MyWebView" component={MyWebView} options={{ headerTitle: "" }} />
        </Stack.Navigator>
    );
}

export default Navigator;
