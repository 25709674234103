import React from 'react';
import { View, Text, Image, FlatList, TouchableOpacity, StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import colors from '../../strings/colors';
import SiskusIcon from '../../components/common/SiskusIcon';

const Wallet = () => {
  // Hardcoded data for demonstration
  const accountStatus = 'Active';
  const profilePicUri = 'https://cdn-icons-png.flaticon.com/512/1361/1361913.png';
  const balance = 'N$1,000';
  const transactions = [
    { id: '1', date: '2023-01-01', amount: 'N$100', customerName: 'John Doe', phoneNumber: '123-456-7890' },
    { id: '2', date: '2023-01-02', amount: 'N$200', customerName: 'Jane Smith', phoneNumber: '234-567-8901' },
    // ... more transactions ...
  ];


  const MenuButton = ({ iconName, title }) => (
    <TouchableOpacity style={styles.menuButton}>
      <Icon color={colors.yellow} name={iconName} size={24} />
      <Text style={styles.menuButtonText}>{title}</Text>
    </TouchableOpacity>
  );

  return (
    <View style={styles.container}>
      {/* Wallet Header */}
      <SiskusIcon title={'SISKUS WALLET'} description="IMMANUEL N FRANSISKUS" />

      {/* Balance Display */}
      <View style={styles.balanceContainer}>
        <Text style={styles.status}>BALANCE</Text>
        <Text style={styles.balanceText}>{balance}</Text>
      </View>

      {/* Menu Component */}
      <View style={styles.menu}>
        <MenuButton iconName="send" title="Send" />
        <MenuButton iconName="people" title="Operators" />
        <MenuButton iconName="wallet" title="Account" />
        <MenuButton iconName="add" title="Top Up" />
      </View>

      {/* Recent Sales */}
      <View style={styles.recentSalesHeader}>
        <Text style={styles.recentSalesText}>Recent Sales</Text>
        <TouchableOpacity>
          <Text style={styles.viewAllText}>View All</Text>
        </TouchableOpacity>
      </View>
      <FlatList
        data={transactions}
        renderItem={({ item }) => (
          <View style={styles.transactionItem}>
            <Text>{item.date}</Text>
            <Text>{item.amount}</Text>
            <Text>{item.customerName}</Text>
            <Text>{item.phoneNumber}</Text>
          </View>
        )}
        keyExtractor={item => item.id}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    backgroundColor: colors.white,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    paddingLeft: 20,
  },
  headerText: {
    fontSize: 24,
    fontWeight: 'bold',
    color: colors.gray,
    fontFamily: 'Poppins',
  },
  status: {
    fontSize: 18,
    fontFamily: 'Poppins',
    color: colors.light_gray,
  },
  profilePic: {
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  balanceContainer: {
    backgroundColor: '#043010',
    borderRadius: 40,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    marginTop: 20,
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  balanceText: {
    fontSize: 43,
    fontWeight: 'bold',
    fontFamily: 'Poppins-bold',
    color: colors.yellow
  },
  menu: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 20,
  },
  menuButton: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.dark_green,
    borderRadius: 20,
    flex: 1,
    margin: 5,
    padding: 10,
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  menuButtonText: {
    marginTop: 5,
    fontFamily: 'Poppins',
    color: colors.white,
  },
  recentSalesHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 20,
  },
  recentSalesText: {
    fontSize: 20,
    fontFamily: 'Poppins',
    marginTop: 20,
  },
  viewAllText: {
    fontFamily: 'Poppins',
    color: colors.blue,
  },
  transactionItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
});

export default Wallet;
