import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import RoundedInput from '../../components/common/RoundedInput';
import RoundedButton from '../../components/common/RoundedButton';
import colors from '../../strings/colors';
import { useChangePasswordMutation } from '../../redux/api';
import { useNavigation } from '@react-navigation/core';
import Toast from 'react-native-toast-message';
import SiskusIcon from '../../components/common/SiskusIcon';
import Loader from '../../components/common/Loader';
import { useSelector } from 'react-redux';

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [status, setStatus] = useState('');

  const [changePassword, changePasswordResult] = useChangePasswordMutation();

  const user_state = useSelector((state) => state.user);

  const navigation = useNavigation();

  const handleOldPasswordChange = (text) => {
    setOldPassword(text);
  };

  const handleNewPasswordChange = (text) => {
    setNewPassword(text);
  };

  const handleChangePassword = () => {
    if (oldPassword === '' || newPassword === '') {
      Toast.show({
        type: 'error',
        text1: 'Validation Error',
        text2: 'Please fill in all fields'
      });
      return;
    }
    changePassword({ oldPassword, newPassword, _id: user_state._id });
  };

  useEffect(() => {
    if (changePasswordResult.isSuccess) {
      setStatus(changePasswordResult.data.message);
      if (changePasswordResult.data.success === true) {
        Toast.show({
          type: 'success',
          text1: 'Success',
          text2: changePasswordResult.data.message
        });
        navigation.navigate('Profile');
      }
    }
    if (changePasswordResult.isError) {
      Toast.show({
        type: 'error',
        text1: 'Error',
        text2: changePasswordResult.error.message
      });
    }
  }, [changePasswordResult]);

  if (changePasswordResult.isLoading) {
    return (
      <Loader/>
    );
  }

  return (
    <View style={styles.container}>
      <SiskusIcon title={'Change Your Password'} description={'Ensure your new password is secure'} />
      <RoundedInput placeholder="Old Password" onChangeText={handleOldPasswordChange} isPassword={true} />
      <RoundedInput placeholder="New Password" onChangeText={handleNewPasswordChange} isPassword={true} />
      <Text style={styles.statusText}>{status}</Text>
      <RoundedButton title="CHANGE PASSWORD" onPress={handleChangePassword} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
      backgroundColor: colors.white,
      justifyContent: 'center',
      alignItems: 'center',
      padding: 20,
  },
  statusText: {
    color: colors.orange,
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center',
  },
});

export default ChangePassword;
