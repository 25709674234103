import React, { useState } from 'react';
import { StyleSheet, View, TextInput, Dimensions, Platform } from 'react-native';

const screenWidth = Dimensions.get('window').width;

const RoundedInput = ({ 
    placeholder, 
    onChangeText, 
    isPassword = false, 
    keyboardType = 'default', 
    value 
}) => {
    const [isPasswordVisible, setPasswordVisibility] = useState(false);

    return (
        <View style={styles.inputContainer}>
            <TextInput
                style={styles.input}
                placeholder={placeholder}
                onChangeText={onChangeText}
                placeholderTextColor="gray"
                value={value}
                underlineColorAndroid="transparent"
                secureTextEntry={isPassword && !isPasswordVisible}
                keyboardType={keyboardType}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    inputContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 25,
        backgroundColor: 'lightgray',
        width: '100%',
        borderColor: '#333',
        marginBottom: 15,
    },
    input: {
        fontFamily: Platform.OS === 'android' ? 'Roboto' : 'Poppins-Bold', // Conditional font
        borderRadius: 25,
        backgroundColor: 'lightgray',
        paddingHorizontal: screenWidth * 0.04, // Responsive padding
        flex: 1,
        paddingVertical: 12, // Fixed vertical padding
        fontSize: screenWidth * 0.04, // Responsive font size
        borderColor: 'transparent',
        borderWidth: 0,
    },
});

export default RoundedInput;
