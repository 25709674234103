/**
 * Jackpot.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */
import React, { useEffect, useState, useCallback, memo } from "react";
import FlatButton from "../../components/jackpot/FlatButton";
import LEDDisplay from "../../components/jackpot/LEDDisplay";
import RouletteWheel from "../../components/jackpot/RouletteWheel";
import WinningMultiplier from "../../components/jackpot/WinningMultiplier";
import colors from "../../strings/colors";
import {
  StatusBar,
  Platform,
  SafeAreaView,
  Dimensions,
  StyleSheet,
  View,
} from "react-native";
import {
  useGetBalanceQuery,
  usePlayMutation,
  useGetSettingsQuery,
} from "../../redux/api";
import { useSelector, useDispatch } from "react-redux";
import { useNavigation } from "@react-navigation/core";
import {
  SPIN_ONCE,
  DECELERATE_MAP,
  SPIN_TO_MAP,
  MULTIPLIER_MAP,
  SPIN_MAP,
  CONSTANT_SPEED,
  COLOR_MAP,
} from "./game/constants";
import { getFiveDigitsAmount } from "./game/helpers";
import Toast from "react-native-toast-message";
import { colorActions } from "../../redux/colorSlice";
import { numbersSliceActions } from "../../redux/numbersSlice";
import { multipliersSliceActions } from "../../redux/multipliersSlice";
import { keyPadNumbersActions } from "../../redux/keyPadNumbersSlice";
import KeyPad from "../../components/jackpot/KeyPad";
import { gameActions } from "../../redux/gameSlice";
import {
  useBeep,
  useSpinSound,
  useWin100OrHigher,
  useWin50OrHigher,
  useWin10OrHigher
} from "./game/sounds";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function Jackpot() {
  /**
   * redux states
   */
  const user_state = useSelector((state) => state.user);

  const sound_state = useSelector((state) => state.sound);

  const color_state = useSelector((state) => state.color);

  const playBeep = useBeep();
  const playSpinSound = useSpinSound();
  const playWin100OrHigher = useWin100OrHigher();
  const playWin10OrHigher = useWin10OrHigher();
  const playWin50OrHigher = useWin50OrHigher();


  const keypad_numbers = useSelector(
    (state) => state.keyPadNumbers.keyPadNumbers
  );

  const { isSpinning } = useSelector((state) => state.game);

  //dispatch
  const dispatch = useDispatch();

  /**
   * RTK
   */

  const [play, playResult] = usePlayMutation();

  const {
    data: balance_data,
    isLoading: balance_isLoading,
    isSuccess: loadingBalanceSuccessfull,
    isError: loadingBalanceFailed,
    refetch: refetchBalance,
  } = useGetBalanceQuery(user_state._id);

  const {
    data: settings_data,
    isLoading: settings_isLoading,
    isSuccess: loadingSettingsSucceful,
    isError: loadingSettingsFailed,
    refetch: refetchSettings,
  } = useGetSettingsQuery(user_state._id);



  //navigation
  const navigation = useNavigation();

  StatusBar.setBarStyle("light-content"); // set the status bar text color to light
  if (Platform.OS === "ios") {
    StatusBar.setBarStyle("dark-content"); // set the status bar text color to dark
  } else if (Platform.OS === "web") {
    StatusBar.setBarStyle("light-content"); // set the status bar text color to light
  }

  StatusBar.setHidden(true);

  const [prevIndex, setPrevIndex] = useState(0);

  //credit and win amount

  const [credit, setCredit] = useState(0);
  const [winAmount, setWinAmount] = useState(0);

  const playSound = (name) => {
    if (sound_state.isSoundOn) {
      switch (name) {
        case "spin":
          playSpinSound();
          break;
        case "beep":
          playBeep();
          break;
        case "win":
          playWin10OrHigher();
          break;
        case "win_50":
          playWin50OrHigher();
          break;
        case "win_100":
          playWin100OrHigher();
        default:
          break;
      }
    }
  };

  /**
   * turn all keys off
   */
  const turnAllKeysOff = () => {
    dispatch(keyPadNumbersActions.resetAllKeys());
  };
  /**
   *
   * @returns return numbers played
   */
  const getNumbersPlayed = () => {
    // Use useSelector to retrieve the keypad numbers from the Redux store
    const results = [];
    keypad_numbers.forEach((item) => {
      const sumForKey = item.keys.reduce(
        (accum, key) => (key.isOn ? accum + key.number : accum),
        0
      );
      if (sumForKey !== 0) {
        results.push({
          number: item.number,
          times: sumForKey,
        });
      }
    });

    return results;
  };

  /**
   * handle start
   */
  const handleStart = useCallback(async () => {
    const numbersPlayed = getNumbersPlayed();
    if (numbersPlayed.length === 0) {
      Toast.show({
        type: "error",
        text1: "Place Your Bet",
        text2: "Please select at least one number to play",
      });
      return;
    }
    if (credit < 1) {
      Toast.show({
        type: "error",
        text1: "Insufficient Credit",
        text2: "You do not have enough credit to play",
      });
      return;
    }
    if (isSpinning) {

      Toast.show({
        type: "error",
        text1: "Spin in Progress",
        text2: "Please wait for the current spin to finish",
      });
      return;
    }

    dispatch(gameActions.setIsSpinning())

    console.log(isSpinning);

    const playData = {
      userId: user_state._id,
      numbersPlayed,
      location: "namibia",
    };
    let totalPlayedAmount = 0;
    numbersPlayed.forEach((item) => {
      totalPlayedAmount += item.times;
    });
    if (totalPlayedAmount > credit) {
      Toast.show({
        type: "error",
        text1: "Insufficient Credit",
        text2: "You do not have enough credit to play",
      });

      dispatch(gameActions.setIsNotSpinning())

      return;
    }
    setCredit(credit - totalPlayedAmount);
    play(playData);
  }, [user_state._id, credit, isSpinning, getNumbersPlayed]);

  const shuffleMultipliers = useCallback(async (winning_multiplier) => {
    let shuffle_indexes = MULTIPLIER_MAP[winning_multiplier];

    for (let i = 0; i < shuffle_indexes.length; i++) {
      // Reset all multipliers to isOn: false
      dispatch(multipliersSliceActions.resetMultipliers());
      // Set the specific multiplier to isOn: true
      dispatch(multipliersSliceActions.setOn(shuffle_indexes[i]));

      await delay(80); // Assuming delay is a function that returns a promise after a timeout
    }
  }, []);

  //get spin from array
  const spinFromArray = () => {
    return SPIN_MAP[prevIndex];
  };

  const playSpin = useCallback(async (array, deceleration) => {
    for (let i = 0; i < array.length; i++) {
      dispatch(numbersSliceActions.resetNumbers()); // reset all numbers to isOn: false

      dispatch(numbersSliceActions.setOn(array[i])); // set the specific number to isOn: true

      let current_deceleration = 0;
      if (deceleration > 0) {
        current_deceleration = CONSTANT_SPEED + i * CONSTANT_SPEED;
      }

      await delay(CONSTANT_SPEED + current_deceleration);
    }
  }, []);

  const spin2 = useCallback(
    async (winningNumber) => {
      const fromArray = spinFromArray();
      await playSpin(fromArray, 0);
      for (let i = 0; i < 2; i++) {
        await playSpin(SPIN_ONCE, 0);
      }
      await playSpin(SPIN_TO_MAP[winningNumber], 0);
      await playSpin(DECELERATE_MAP[winningNumber], 30);
      setPrevIndex(winningNumber);
    },
    [prevIndex, playSpin]
  );

  const handleCancel = async () => {
    playSound("beep");
    turnAllKeysOff();
  };

  const handlePayin = async () => {
    navigation.navigate("Payin");
  };

  const handlePayout = () => {
    navigation.navigate("Payout");
  };

  //animate set credit by incrementing by 1 and decrementing win amount by 1

  const animateCredit = useCallback(
    async (total_winning_amount) => {
      const BLINK_COUNT = 3;
      const BLINK_DELAY = 500;
      const INCREMENT_DELAY = 16;

      // Blinking effect
      for (let i = 0; i < BLINK_COUNT; i++) {
        setWinAmount(total_winning_amount);
        await delay(BLINK_DELAY);
        setWinAmount(0);
        await delay(BLINK_DELAY);
      }
      setWinAmount(total_winning_amount);

      // Incrementing credit effect
      let credit_copy = credit;
      let win_amount_copy = total_winning_amount;
      while (credit_copy < credit + total_winning_amount) {
        credit_copy++;
        win_amount_copy--;
        setCredit(credit_copy);
        setWinAmount(win_amount_copy);
        await delay(INCREMENT_DELAY);
      }
    },
    [credit, winAmount]
  );

  useEffect(() => {
    refetchBalance();
    refetchSettings();
  }, [refetchBalance, refetchSettings]);

  useEffect(() => {
    if (loadingBalanceSuccessfull) {
      setCredit(balance_data.balance);
    }
    if (loadingSettingsSucceful) {
      dispatch(colorActions.setColor(settings_data.background.toLowerCase()));
    }
    if (loadingBalanceFailed) {
      Toast.show({
        type: "error",
        text1: "Error",
        text2: "Unable to balance, please try again later",
      });
    }

    if (loadingSettingsFailed) {
      Toast.show({
        type: "error",
        text1: "Error",
        text2: "Unable to load settings, please try again later",
      });
    }
  }, [balance_isLoading, settings_isLoading, balance_data, settings_data]);

  useEffect(() => {
    const spinWheel = async () => {

      if (!playResult || !playResult.data) {
        return;
      }
  
      if (playResult.isError) {
        dispatch(gameActions.setIsNotSpinning())
  
        const error_message = playResult.error.status === 'FETCH_ERROR' 
          ? 'You are offline, please check your internet connection and try again later' 
          : 'Unable to play now, please try again later';
  
        Toast.show({
          type: "error",
          text1: "Unable to play",
          text2: error_message,
        });
  
        return;
      }
  
      // Now we know playResult.data is defined
      if (playResult.data.success === false) {
        dispatch(gameActions.setIsNotSpinning())
        Toast.show({
          type: "error",
          text1: "Unable to play",
          text2: playResult.data.message,
        });
        return;
      }

        playSound("spin");
        await spin2(playResult.data.number);

       
      

      if (playResult.data.won === true) {

        const total_winning_amount = playResult.data.winning_amount * playResult.data.times;

        if (total_winning_amount >= 100) {
          playSound("win_100");
        } else if (total_winning_amount >= 50) {
          playSound("win_50");
        } else {
          playSound("win");
        }
        shuffleMultipliers(playResult.data.winning_amount);
        await delay(1280);
        await delay(1280);
        await animateCredit(total_winning_amount);
      } else {
        //playSound('beep');
        setCredit(playResult.data.new_balance);
      }

      dispatch(gameActions.setIsNotSpinning())
      
    };
   
    spinWheel();
    

  }, [playResult]);

  return (
    <SafeAreaView style={styles.main}>
      <View
        style={[
          styles.container,
          { backgroundColor: COLOR_MAP[color_state.value]["light"] },
        ]}
      >
        <View
          style={[
            styles.upperContainer,
            { backgroundColor: COLOR_MAP[color_state.value]["dark"] },
          ]}
        >
          <View style={styles.leftContainer}>
            <FlatButton
              color={"yellow"}
              title="PAYIN"
              onPress={() => handlePayin()}
            />
            <FlatButton
              color={"red"}
              title="PAYOUT"
              onPress={() => handlePayout()}
            />
          </View>
          <View style={styles.centerContainer}>
            <RouletteWheel isLoading={playResult.isLoading} />
          </View>
          <View style={styles.rightContainer}>
            <FlatButton
              color={"red"}
              title="CANCEL"
              onPress={() => handleCancel()}
            />
            <FlatButton
              color={"green"}
              title="START"
              disabled={isSpinning}
              onPress={() => handleStart()}
            />
          </View>
        </View>
        <View style={styles.displays}>
          <View style={[styles.leftContainer, { marginLeft: 1 }]}>
            <LEDDisplay name={"WIN"} amount={getFiveDigitsAmount(winAmount)} />
          </View>
          <View style={[styles.centerContainer, { width: 200 }]}>
            <WinningMultiplier />
          </View>
          <View style={styles.rightContainer}>
            <LEDDisplay name={"CREDIT"} amount={getFiveDigitsAmount(credit)} />
          </View>
        </View>
        <KeyPad />
      </View>

    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  main: {
    backgroundColor: colors.gray,
    padding: 0,
  },
  container: {
    backgroundColor: colors.green,
    height: Dimensions.get("window").height - 63,
    width: Dimensions.get("window").width,
    maxWidth: 500,
    minHeight: 700,
    alignItems: "center",
    justifyContent: "flex-start",
    padding: 2,
    margin: "auto",
    marginTop: 0,
    flexDirection: "column",
  },
  upperContainer: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    marginTop: 10,
    height: 180,
    borderRadius: 10,
    width: "98%",
    margin: "auto",
    padding: 10,
    backgroundColor: colors.light_blue,
  },
  displays: {
    flexDirection: "row",
    marginTop: 2,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    width: "98%",
    height: 100,
    margin: "auto",
    padding: 0,
  },
  leftContainer: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
  },
  rightContainer: {
    flex: 1,
    alignItems: "flex-end",
    justifyContent: "center",
    flexDirection: "column",
  },
  centerContainer: {
    flex: 2,
    justifyContent: "center",
    flexDirection: "column",
  },
});

export default memo(Jackpot);
