import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { Ionicons } from '@expo/vector-icons'; // or import from another icon library
import colors from '../../strings/colors';

const MenuItem = ({ icon, label, onPress }) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.container}>
        <View style={styles.iconContainer}>
          <Ionicons name={icon} size={36} color={colors.gold} />
        </View>
        <Text style={styles.label}>{label}</Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 90,
    height: 90,
    borderRadius: 14,
    backgroundColor:  'rgba(0, 0, 0, 0.96)',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
  },
  iconContainer: {
    width: 50,
    height: 50,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    color: '#fff',
    textAlign:'center',
    marginTop: 5,
  },
});

export default MenuItem;
