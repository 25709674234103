/**
 * WhatsApp.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */
import React, { useCallback } from 'react';
import { TouchableOpacity, Linking, StyleSheet } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import colors from '../../strings/colors';
import Toast from 'react-native-toast-message';

const styles = StyleSheet.create({
  icon: {
    marginRight: 25
  }
});

const WhatsApp = ({ number, message }) => {
  
  const sendMessage = useCallback(() => {
    let url = `whatsapp://send?text=${encodeURIComponent(message)}&phone=${number}`;
    Linking.openURL(url).catch(() => {
      Toast.show({
        type: 'error',
        text1: 'Error',
        text2: 'Please install WhatsApp to use this feature',
        position: 'top',
      });
    });
  }, [number, message]);

  return (
    <TouchableOpacity style={styles.icon} onPress={sendMessage}>
      <MaterialCommunityIcons name="whatsapp" size={28} color={colors.light_green} />
    </TouchableOpacity>
  );
};

export default React.memo(WhatsApp);
