import React, { useState, useCallback } from 'react';
import { View, StyleSheet, Image, ActivityIndicator } from 'react-native';
import SpinWheelNumber from './SpinWheelNumber';
import colors from '../../strings/colors';
import { useSelector } from 'react-redux';

const spinImage = require('../../../assets/images/spin.svg');

const styles = StyleSheet.create({
  loaderContainer: {
    position: 'absolute',
    width: 150,
    height: 150,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    borderRadius: 75,
    backgroundColor: 'rgba(19, 19, 19, 0.5)',  // Optional: semi-transparent background
  },
  container: {
    flex: 1,
    justifyContent: 'center', 
    alignItems: 'center',
    marginTop: 0,
  },
  innerView: {
    width: 150,
    height: 150,
  },
  image: {
    width: 150, 
    height: 150, 
    marginTop: 0
  },
  numberContainer: {
    position: 'absolute',
    marginTop: 3,
  },
});

const RouletteWheel = ({ isLoading = false }) => {
  const { numbers } = useSelector(state => state.numbers);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const renderSpinWheelNumbers = useCallback(() => {
    return numbers.map(({ number, color, size, angle, isOn, marginTop, marginLeft }, index) => (
      <SpinWheelNumber
        key={index}
        number={number}
        color={color}
        size={size}
        angle={angle}
        isOn={isOn}
        marginTop={marginTop}
        marginLeft={marginLeft}
      />
    ));
  }, [numbers]);

  return (
    <View style={styles.container}>
      <View style={styles.innerView}>
        <Image 
          source={spinImage} 
          style={styles.image} 
          onLoad={handleImageLoad}
        />
        {imageLoaded && (
          <View style={styles.numberContainer}>
            {renderSpinWheelNumbers()}
          </View>
        )}
      </View>
      {isLoading && (
        <View style={styles.loaderContainer}>
          <ActivityIndicator size="large" color={colors.white} />
        </View>
      )}
    </View>
  );
};

export default React.memo(RouletteWheel);
