/**
 * formater.helper.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

const getFiveDigitsAmount = (amount) => {
  const amountString = amount.toString();
  const amountLength = amountString.length;

  // Simplify if more than 5 digits
  if (amountLength > 5) {
      // Trillions
      if (amount >= 1e12) {
          return (amount / 1e12).toFixed(2) + 'T';
      }
      // Billions
      else if (amount >= 1e9) {
          return (amount / 1e9).toFixed(2) + 'B';
      }
      // Millions
      else if (amount >= 1e6) {
          return (amount / 1e6).toFixed(2) + 'M';
      }
      // Thousands
      else if (amount >= 1e3) {
          return (amount / 1e3).toFixed(2) + 'K';
      }
  }

  // Pad with zeros if less than 5 digits
  if (amountLength < 5) {
      const diff = 5 - amountLength;
      return '0'.repeat(diff) + amountString;
  }

  return amountString;
};



export {
    getFiveDigitsAmount
}