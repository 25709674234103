import React from 'react';
import { TouchableOpacity, Text, StyleSheet} from 'react-native';
import colors from '../../strings/colors';

const FlatButton = ({ title, onPress, color, disabled=false }) => {
  
  return (
    <TouchableOpacity
      style={[styles.button, color==='red' && styles.red, color==='green' && styles.green, color==='yellow' && styles.yellow]}
      onPress={onPress}
      disabled={disabled}
      >
      
      <Text style={styles.title}>{title}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({

    button: {
        width: 90,
        height: 40,
        marginTop: 10,
        marginBottom: 10,
        alignItems: 'center',
        justifyContent: 'center',
        
    },

  red: {
    backgroundColor: '#f24537',
    borderColor: '#d02718',
    shadowColor: '#f5978e',
    borderWidth: 2,
    borderRadius: 6,
  },
  green:{
   
    backgroundColor: colors.light_green,
    borderColor: '#00843d',
    shadowColor: '#00a651',
    borderWidth: 2,
    borderRadius: 6,
    
    
  },

  yellow:{
    
    backgroundColor: '#f2c94c',
    borderColor: colors.gold,
    
    borderWidth: 2,
    borderRadius: 6,
   
    
  },

  title: {
    fontFamily: 'Gloucester',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    color: colors.black,
  },
});

export default FlatButton;
